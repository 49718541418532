/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Fullmap, FullmapWrap, FullmapCover } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"aktuality"}>
        <SiteHeader />

        <Column className="css-1w05u9u --style2 --full --parallax" name={"uvod"} parallax={true} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19002/3c6ecfe6a1024c7db36e9665ae87e507_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19002/3c6ecfe6a1024c7db36e9665ae87e507_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19002/3c6ecfe6a1024c7db36e9665ae87e507_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19002/3c6ecfe6a1024c7db36e9665ae87e507_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19002/3c6ecfe6a1024c7db36e9665ae87e507_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19002/3c6ecfe6a1024c7db36e9665ae87e507_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19002/3c6ecfe6a1024c7db36e9665ae87e507_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19002/3c6ecfe6a1024c7db36e9665ae87e507_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" style={{"maxWidth":1300}} columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s3 pb--0 pl--0 pr--0 pt--0" anim={"2"} animS={"3"}>
              
              <Title className="title-box fs--102" style={{"maxWidth":1276}} content={"<span style=\"color: rgb(252, 1, 1); font-weight: bold;\">Aktuality<br><br><br><br><br><br></span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--50" name={"o-nas"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex el--2 mb--10 mt--10" style={{"maxWidth":1300}} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box fs--36" style={{"maxWidth":383}} content={"<span style=\"color: rgb(243, 7, 7); font-weight: bold;\">Marcela a Zdeněk Palíškovi</span><br>"}>
              </Title>

              <Text className="text-box" content={"<span style=\"color: var(--color-dominant);\">Mobil: 776 352 383<br>paliskova.keramika@seznam.cz<br></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Text className="text-box" style={{"maxWidth":474}} content={"<span style=\"font-weight: bold; color: rgb(247, 6, 6);\">Křenůvky jsou malá obec na úpatí Drahanské vrchoviny. <br>A za ní už jsou jen lesy vojenského újezdu Březina.</span>"}>
              </Text>

              <Text className="text-box" content={"<span style=\"color: var(--color-dominant);\"><br><br><br>Vytvořeno přes <a style=\"color: inherit\" href=\"https://saywebpage.com\">saywebpage.com</a></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1b4rt3h pb--60 pt--60" name={"gtzmzxhtkii"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/19002/82a9a1d938db49cdb513abb37f68989d_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/19002/82a9a1d938db49cdb513abb37f68989d_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/19002/82a9a1d938db49cdb513abb37f68989d_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/19002/82a9a1d938db49cdb513abb37f68989d_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/19002/82a9a1d938db49cdb513abb37f68989d_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/19002/82a9a1d938db49cdb513abb37f68989d_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/19002/82a9a1d938db49cdb513abb37f68989d_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/19002/82a9a1d938db49cdb513abb37f68989d_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box" content={"<span style=\"font-weight: bold; color: rgb(249, 3, 3);\">Křenůvky č.10</span>"}>
              </Title>

              <Text className="text-box fs--20 w--600" content={"<span style=\"color: rgb(11, 11, 11);\">Do Křenůvek nejlépe z dálnice D46 ze sjezdu km16 u obce Vranovice-Kelčice. Dále přes Dětkovice, Určice a Alojzov a za lesem už jsou Křenůvky</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Fullmap name={"88lb55yhnmf"}>
          
          <FullmapWrap className="pb--20 pt--20">
          </FullmapWrap>

          <FullmapWrap className="--full">
            
            <FullmapCover zoom={"12"} place={"Křenůvly č.10"}>
            </FullmapCover>

          </FullmapWrap>

        </Fullmap>

      </Layout>
    </ThemeWrapper>
  )
}